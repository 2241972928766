import { AppAction, AppActionType, AppStateType } from './app-actions';

const initialState: AppStateType = {
  failureInterval: undefined,
  user: undefined,
  failure: false
};

// eslint-disable-next-line default-param-last
export default function appReducer(state = initialState, { type, payload }: AppAction): AppStateType {
  switch (type) {
    case AppActionType.SET_FAILURE_INTERVAL:
    case AppActionType.SET_LOGGED_USER:
    case AppActionType.SET_APP_FAILURE:
      return { ...state, ...payload };
    default:
      return state;
  }
}
