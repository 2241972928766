/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function WebSitesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 132 132">
      <g clipPath="url(#clip0_773_35)">
        <path
          d="M68.3592 131.922C78.4533 130.488 87.3021 119.952 92.8704 104.394C84.8209 102.591 76.6068 101.623 68.3592 101.508V131.922Z"
        />
        <path
          d="M84.4857 129.448C85.0824 129.275 85.6768 129.101 86.2666 128.91C86.7642 128.75 87.2549 128.577 87.7456 128.405C88.3261 128.205 88.9044 127.999 89.4803 127.78C89.971 127.593 90.4571 127.395 90.9444 127.197C91.5065 126.961 92.0675 126.726 92.6227 126.49C93.1077 126.276 93.5891 126.053 94.0706 125.828C94.6155 125.571 95.158 125.31 95.696 125.038C96.1682 124.797 96.6394 124.552 97.1116 124.3C97.6404 124.016 98.1656 123.727 98.6874 123.43C99.1597 123.163 99.6147 122.894 100.074 122.618C100.586 122.304 101.095 121.989 101.601 121.675C102.053 121.387 102.505 121.094 102.951 120.792C103.448 120.457 103.939 120.108 104.427 119.759C104.866 119.445 105.303 119.131 105.731 118.816C106.204 118.452 106.687 118.075 107.161 117.697C107.581 117.362 108.001 117.029 108.414 116.685C108.885 116.293 109.337 115.887 109.794 115.482C110.195 115.126 110.598 114.774 110.99 114.408C111.44 113.988 111.879 113.553 112.318 113.121C112.698 112.745 113.083 112.375 113.455 111.991C113.524 111.919 113.589 111.842 113.661 111.768C108.515 109.111 103.096 107.025 97.4964 105.548C94.5844 114.835 89.402 123.25 82.4192 130.03C82.6104 129.981 82.8017 129.941 82.9929 129.891C83.4974 129.749 83.9927 129.594 84.4857 129.448Z"
        />
        <path
          d="M132 68.4131H103.714C103.607 79.4575 102.005 90.4362 98.9512 101.051C105.178 102.714 111.188 105.106 116.855 108.175C126.16 96.9605 131.486 82.9777 132 68.4131Z"
        />
        <path
          d="M68.3592 63.6952H98.9962C98.8764 53.0413 97.3132 42.4531 94.3517 32.2185C85.8208 34.1641 77.1078 35.2031 68.3592 35.3183V63.6952Z"
        />
        <path
          d="M68.3592 0.186523V30.6C76.6068 30.4837 84.8209 29.5172 92.8704 27.7145C87.3021 12.1559 78.4533 1.62063 68.3592 0.186523Z"
        />
        <path
          d="M68.3592 96.79C77.1078 96.9064 85.8208 97.9454 94.3517 99.8909C97.3132 89.6563 98.8764 79.067 98.9962 68.4131H68.3592V96.79Z"
        />
        <path
          d="M116.855 23.9329C111.188 27.0027 105.178 29.394 98.9512 31.0573C102.005 41.672 103.607 52.6508 103.714 63.6951H132C131.485 49.1317 126.159 35.1489 116.855 23.9329Z"
        />
        <path
          d="M113.668 20.354C113.599 20.2838 113.533 20.2077 113.465 20.1352C113.092 19.7504 112.705 19.3795 112.325 19.0052C111.887 18.5732 111.45 18.1366 110.999 17.7174C110.608 17.3534 110.204 17.0089 109.803 16.6461C109.346 16.2406 108.889 15.8317 108.419 15.4401C108.011 15.0956 107.593 14.7662 107.178 14.4356C106.706 14.0532 106.222 13.673 105.736 13.3079C105.31 12.9865 104.878 12.6755 104.443 12.3645C103.95 12.0097 103.455 11.6561 102.953 11.3186C102.512 11.0214 102.064 10.7311 101.616 10.4466C101.105 10.1183 100.596 9.79692 100.077 9.48591C99.6216 9.21291 99.162 8.94337 98.7013 8.68189C98.1737 8.38009 97.645 8.08751 97.1116 7.80184C96.6394 7.5657 96.1682 7.30883 95.696 7.07269C95.1534 6.79854 94.6063 6.53475 94.0453 6.27558C93.573 6.05096 93.0938 5.8321 92.6077 5.619C92.0491 5.37134 91.4846 5.14787 90.9214 4.91174C90.4353 4.71361 89.9515 4.51779 89.4608 4.33118C88.8883 4.11232 88.3077 3.90728 87.7272 3.70685C87.2365 3.53407 86.7457 3.36244 86.2481 3.20117C85.6584 3.00996 85.0686 2.83602 84.4627 2.66093C83.972 2.51695 83.479 2.37066 82.9825 2.23934C82.7924 2.1875 82.5989 2.14719 82.4077 2.09766C89.3905 8.87771 94.5717 17.2923 97.4849 26.58C103.09 25.1033 108.518 23.0161 113.668 20.354Z"
        />
        <path
          d="M-7.62939e-06 63.6951H28.2859C28.393 52.6508 29.9953 41.672 33.0479 31.0573C26.8219 29.3951 20.8124 27.0038 15.1451 23.9329C5.8401 35.1477 0.513738 49.1317 -7.62939e-06 63.6951Z"
        />
        <path
          d="M63.641 131.922V101.508C55.3935 101.625 47.1793 102.591 39.1299 104.394C44.697 119.952 53.547 130.488 63.641 131.922Z"
        />
        <path
          d="M63.641 68.4131H33.0041C33.1239 79.067 34.6859 89.6563 37.6485 99.8909C46.1795 97.9442 54.8912 96.9052 63.641 96.79V68.4131Z"
        />
        <path
          d="M63.641 0.186523C53.547 1.62063 44.697 12.1559 39.1299 27.7145C47.1793 29.5184 55.3935 30.4848 63.641 30.6V0.186523Z"
        />
        <path
          d="M63.641 35.3183C54.8924 35.2031 46.1795 34.1641 37.6485 32.2185C34.6859 42.4531 33.1239 53.0413 33.0041 63.6952H63.641V35.3183Z"
        />
        <path
          d="M49.5741 2.09521C49.3829 2.14475 49.1917 2.18506 49.0005 2.23459C48.5005 2.36821 48.0075 2.51681 47.5099 2.6608C46.9178 2.83358 46.3304 3.00752 45.736 3.19643C45.2349 3.35884 44.7419 3.53163 44.2489 3.70326C43.6718 3.90715 43.0935 4.10988 42.5222 4.32644C42.0292 4.51535 41.5431 4.71117 41.0547 4.9116C40.4937 5.14774 39.9316 5.38387 39.3775 5.61886C38.8914 5.83196 38.4111 6.05543 37.9296 6.28005C37.3847 6.53692 36.8422 6.7984 36.3043 7.07025C35.832 7.31099 35.3597 7.5575 34.8886 7.80861C34.3599 8.09198 33.8358 8.38226 33.3151 8.67945C32.8428 8.94323 32.3786 9.21392 31.9236 9.49038C31.411 9.79909 30.9042 10.1182 30.3997 10.4338C29.9458 10.7241 29.4954 11.0166 29.0496 11.3184C28.552 11.6536 28.0613 12.0027 27.5729 12.3517C27.134 12.6662 26.6975 12.9795 26.2678 13.2962C25.7852 13.6591 25.3244 14.0369 24.8383 14.4136C24.419 14.7488 23.9963 15.0817 23.5862 15.4284C23.1139 15.8177 22.6658 16.2209 22.2108 16.6241C21.8077 16.9835 21.4011 17.3325 21.0071 17.7045C20.559 18.1227 20.1225 18.5569 19.6847 18.9878C19.3046 19.3633 18.9176 19.7342 18.5444 20.1201C18.4764 20.1915 18.4107 20.2698 18.3393 20.3424C23.4837 23.001 28.9045 25.087 34.5039 26.5638C37.4158 17.2818 42.5948 8.87181 49.5741 2.09521Z"
        />
        <path
          d="M19.6744 113.103C20.1132 113.535 20.5498 113.972 21.0002 114.392C21.3918 114.755 21.795 115.099 22.197 115.462C22.6543 115.869 23.1116 116.277 23.5816 116.668C23.9894 117.01 24.4052 117.341 24.8222 117.673C25.2945 118.055 25.7656 118.435 26.2655 118.803C26.6905 119.122 27.1225 119.431 27.5545 119.747C28.0475 120.101 28.5451 120.455 29.0473 120.794C29.4885 121.092 29.9366 121.382 30.3847 121.668C30.895 121.995 31.4041 122.316 31.9236 122.627C32.3786 122.901 32.8382 123.17 33.299 123.432C33.8265 123.734 34.3553 124.027 34.8886 124.311C35.3597 124.547 35.832 124.804 36.3043 125.04C36.8468 125.314 37.394 125.578 37.9549 125.839C38.4272 126.061 38.9064 126.28 39.3925 126.494C39.9512 126.742 40.5144 126.965 41.0789 127.201C41.565 127.399 42.0488 127.595 42.5395 127.782C43.112 128.002 43.6925 128.207 44.2731 128.407C44.7638 128.579 45.2545 128.752 45.7521 128.912C46.3419 129.103 46.9316 129.278 47.5375 129.452C48.0282 129.596 48.5213 129.742 49.0166 129.875C49.2078 129.927 49.4013 129.967 49.5925 130.016C42.6097 123.235 37.4285 114.821 34.5154 105.533C28.916 107.011 23.4952 109.097 18.3508 111.757C18.4199 111.827 18.4856 111.903 18.5547 111.976C18.9083 112.358 19.2896 112.729 19.6744 113.103Z"
        />
        <path
          d="M15.1451 108.175C20.8124 105.106 26.8219 102.714 33.0479 101.051C29.9953 90.4362 28.393 79.4575 28.2859 68.4131H-7.62939e-06C0.514889 82.9765 5.8401 96.9594 15.1451 108.175Z"
        />
      </g>
    </SvgIcon>
  );
}
