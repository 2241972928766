import React from 'react';
import Dynamic from 'next/dynamic';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import store from '@redux/store';
import ColorModeContextProvider from './ColorModeContext';

const SnackbarUtilsConfigurator = Dynamic(
  () => import('@components/utils/SnackbarUtils'),
  { ssr: false }
);

export default function OpsiyonProvider({ children }: {children: React.ReactNode}) {
  return (
    <Provider store={store}>
      <ColorModeContextProvider>
        <SnackbarProvider maxSnack={5}>
          {children}
          <SnackbarUtilsConfigurator />
        </SnackbarProvider>
      </ColorModeContextProvider>
    </Provider>
  );
}
