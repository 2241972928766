import IManagementUserDetailDTO from '@modules/auth/types/IManagementUserDetailDTO';

export enum AppActionType {
  SET_FAILURE_INTERVAL = 'SET_FAILURE_INTERVAL',
  SET_APP_FAILURE = 'SET_APP_FAILURE',
  SET_LOGGED_USER = 'SET_LOGGED_USER'
}

export type AppStateType = {
  failure?: boolean;
  failureInterval?: NodeJS.Timer;
  user?: IManagementUserDetailDTO;
};

export type AppAction = {
  type: AppActionType;
  payload: AppStateType;
};

export function setAppFailureInternalAction(interval?: NodeJS.Timer): AppAction {
  return {
    type: AppActionType.SET_FAILURE_INTERVAL,
    payload: { failureInterval: interval },
  };
}

export function setAppFailureAction(failure?: boolean): AppAction {
  return {
    type: AppActionType.SET_APP_FAILURE,
    payload: { failure },
  };
}

export function setAppLoggedUserAction(user?: IManagementUserDetailDTO): AppAction {
  return {
    type: AppActionType.SET_LOGGED_USER,
    payload: { user },
  };
}
