/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function BusinessFeedbackIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 436 421">
      <path d="M337.466 0H430.894C433.655 0 435.894 2.23858 435.894 5V52.4161C435.894 55.1776 433.655 57.4161 430.894 57.4161H342.466C339.704 57.4161 337.466 55.1776 337.466 52.4161V0Z" />
      <path d="M407.934 53.6158C400.794 53.6158 393.998 51.3805 388.328 47.2495C384.756 51.1595 379.63 53.6158 373.943 53.6158H360.924C350.138 53.6158 341.395 44.8337 341.395 34.0003V0H264.583C260.988 0 258.073 2.92751 258.073 6.53851V139.285C258.073 141.585 259.174 143.795 261.112 145.02C262.177 145.694 263.382 146.027 264.582 146.027C265.845 146.027 267.105 145.658 268.193 144.929L292.592 128.591H429.49C433.085 128.591 436 125.663 436 122.052V53.6158H407.934ZM290.621 32.6926H327.508C331.103 32.6926 334.018 35.6201 334.018 39.2311C334.018 42.8421 331.103 45.7696 327.508 45.7696H290.621C287.026 45.7696 284.111 42.8421 284.111 39.2311C284.111 35.6201 287.026 32.6926 290.621 32.6926ZM403.452 104.616H290.621C287.026 104.616 284.111 101.689 284.111 98.0777C284.111 94.4667 287.026 91.5392 290.621 91.5392H403.452C407.047 91.5392 409.962 94.4667 409.962 98.0777C409.962 101.689 407.047 104.616 403.452 104.616ZM403.452 75.1929H290.621C287.026 75.1929 284.111 72.2654 284.111 68.6544C284.111 65.0434 287.026 62.1159 290.621 62.1159H403.452C407.047 62.1159 409.962 65.0434 409.962 68.6544C409.962 72.2654 407.047 75.1929 403.452 75.1929Z" />
      <path d="M90.6177 225.491H146.767V164.272H99.9169L90.6177 225.491Z" />
      <path d="M101.094 365.04V243.76H19.8042V365.04H101.094ZM89.3209 292.335V311.312H71.0508V292.335H89.3209Z" />
      <path d="M165.038 225.491H221.192L211.893 164.272H165.038V225.491Z" />
      <path d="M72.138 225.491L81.4372 164.272H35.0674L7.46484 225.491H72.138Z" />
      <path d="M119.365 365.04H161.284V333.7H143.927V315.43H196.91V333.7H179.554V365.04H232.841V333.7H215.485V315.43H268.468V333.7H251.111V365.04H292.005V243.76H119.365V365.04Z" />
      <path d="M56.1108 108.853H255.697V146.002H56.1108V108.853Z" />
      <path d="M0 384.845H311.809V420.662H0V384.845Z" />
      <path d="M230.371 164.272L239.67 225.491H304.343L276.741 164.272H230.371Z" />
    </SvgIcon>
  );
}
