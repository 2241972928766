/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function AdvisorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 608 671">
      <path d="M608 581.755V671H0V581.736C0 539.592 36.3151 487.712 80.6781 466.63L179.395 419.707L247.922 562.578L283.008 467.403C289.926 468.712 296.785 470.458 304 470.458C311.215 470.458 318.054 468.712 324.992 467.403L358.294 557.897L425.473 418.121L527.381 466.61C571.685 487.692 608 539.573 608 581.755ZM89.6776 268.963V204.509C89.6776 193.581 95.3667 184.002 103.95 178.489C115.467 78.1782 200.744 0 304.04 0C407.335 0 492.632 78.1782 504.169 178.489C512.732 184.002 518.422 193.581 518.422 204.509V268.963C518.422 286.078 504.565 299.961 487.439 299.961C470.312 299.961 456.456 286.078 456.456 268.963V255.418C439.686 339.804 377.938 418.14 304.059 418.14C230.18 418.14 168.433 339.804 151.663 255.418V268.963C151.663 282.846 142.445 294.467 129.858 298.414C142.188 340.399 169.543 376.671 207.325 400.47C214.303 398.17 223.104 398.606 231.647 403.048C245.365 410.188 252.362 424.07 247.168 433.986C241.995 443.902 226.692 446.124 212.895 438.964C202.825 433.709 196.442 424.883 195.768 416.633C152.496 388.948 121.81 346.566 109.143 297.68C97.7653 293.119 89.6776 282.013 89.6776 268.963ZM125.061 171.25C125.993 171.31 126.885 171.33 127.777 171.667C130.017 172.5 131.642 174.106 132.772 175.99C139.492 178.846 144.844 184.062 148.135 190.527C157.273 104.833 223.48 59.4964 304 59.4964C384.52 59.4964 450.727 104.833 459.865 190.527C464.405 181.623 473.028 175.256 483.355 173.928C469.955 86.8052 394.748 19.8123 304 19.8123C214.184 19.8321 139.571 85.4566 125.061 171.25Z" />
    </SvgIcon>
  );
}

