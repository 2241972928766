/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function AccountingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 156 156">
      <path
        d="M40.4125 106.994C33.6434 106.994 28.1562 112.481 28.1562 119.25C28.1562 126.019 33.6434 131.506 40.4125 131.506C47.1816 131.506 52.6688 126.019 52.6688 119.25C52.6615 112.484 47.1786 107.001 40.4125 106.994ZM40.4125 129.055C34.9972 129.055 30.6075 124.665 30.6075 119.25C30.6075 113.835 34.9972 109.445 40.4125 109.445C45.8278 109.445 50.2175 113.835 50.2175 119.25C50.2115 124.662 45.8248 129.049 40.4125 129.055Z"
      />
      <path
        d="M40.4125 115.606C41.9293 115.606 42.8969 116.325 42.8969 116.821H45.3812C45.3812 115.095 43.8177 113.697 41.6547 113.299V111.962H39.1703V113.299C37.0072 113.694 35.4437 115.093 35.4437 116.821C35.4437 118.865 37.626 120.465 40.4125 120.465C41.9293 120.465 42.8969 121.183 42.8969 121.679C42.8969 122.175 41.9293 122.894 40.4125 122.894C38.8957 122.894 37.9281 122.175 37.9281 121.679H35.4437C35.4437 123.405 37.0072 124.803 39.1703 125.201V126.537H41.6547V125.201C43.8177 124.806 45.3812 123.407 45.3812 121.679C45.3812 119.635 43.199 118.035 40.4125 118.035C38.8957 118.035 37.9281 117.316 37.9281 116.821C37.9281 116.325 38.8957 115.606 40.4125 115.606Z"
      />
      <path
        d="M135.15 153.7H23.85V90.1H66.25C70.6404 90.1 74.2 86.5404 74.2 82.15V15.9H103.35V47.7H135.15V84.8H140.45V43.9529L107.097 10.6H74.2V7.95C74.2 3.55965 70.6404 0 66.25 0H7.95C3.55965 0 0 3.55965 0 7.95V82.15C0 86.5404 3.55965 90.1 7.95 90.1H18.55V159H140.45V121.9H135.15V153.7ZM108.65 19.6473L131.403 42.4H108.65V19.6473ZM5.3 82.15V7.95C5.3 6.48654 6.48654 5.3 7.95 5.3H66.25C67.7135 5.3 68.9 6.48654 68.9 7.95V82.15C68.9 83.6135 67.7135 84.8 66.25 84.8H7.95C6.48654 84.8 5.3 83.6135 5.3 82.15Z"
      />
      <path
        d="M84.8 66.25H90.1V71.55H84.8V66.25Z"
      />
      <path
        d="M95.4 66.25H127.2V71.55H95.4V66.25Z"
      />
      <path
        d="M84.8 55.6499H90.1V60.9499H84.8V55.6499Z"
      />
      <path
        d="M95.4 55.6499H127.2V60.9499H95.4V55.6499Z"
      />
      <path
        d="M84.8 76.8501H90.1V82.1501H84.8V76.8501Z"
      />
      <path
        d="M95.4 76.8501H127.2V82.1501H95.4V76.8501Z"
      />
      <path
        d="M84.8 87.45H90.1V92.7499H84.8V87.45Z"
      />
      <path
        d="M95.4 87.45H127.2V92.7499H95.4V87.45Z"
      />
      <path
        d="M66.2499 7.94995H7.94995V26.5H66.2499V7.94995ZM60.9499 21.2H13.25V13.25H60.9499V21.2Z"
      />
      <path
        d="M21.2 29.1499H15.9C12.9731 29.1499 10.6 31.523 10.6 34.4499V39.7499C10.6 42.6768 12.9731 45.0499 15.9 45.0499H21.2C24.1269 45.0499 26.5 42.6768 26.5 39.7499V34.4499C26.5 31.523 24.1269 29.1499 21.2 29.1499ZM15.9 39.7499V34.4499H21.2V39.7499H15.9Z"
      />
      <path
        d="M39.75 29.1499H34.45C31.5231 29.1499 29.15 31.523 29.15 34.4499V39.7499C29.15 42.6768 31.5231 45.0499 34.45 45.0499H39.75C42.6769 45.0499 45.05 42.6768 45.05 39.7499V34.4499C45.05 31.523 42.6769 29.1499 39.75 29.1499ZM34.45 39.7499V34.4499H39.75V39.7499H34.45Z"
      />
      <path
        d="M58.3 29.1499H53C50.073 29.1499 47.7 31.523 47.7 34.4499V39.7499C47.7 42.6768 50.073 45.0499 53 45.0499H58.3C61.2269 45.0499 63.6 42.6768 63.6 39.7499V34.4499C63.6 31.523 61.2269 29.1499 58.3 29.1499ZM53 39.7499V34.4499H58.3V39.7499H53Z"
      />
      <path
        d="M21.2 47.7H15.9C12.9731 47.7 10.6 50.073 10.6 53V58.3C10.6 61.2269 12.9731 63.6 15.9 63.6H21.2C24.1269 63.6 26.5 61.2269 26.5 58.3V53C26.5 50.073 24.1269 47.7 21.2 47.7ZM15.9 58.3V53H21.2V58.3H15.9Z"
      />
      <path
        d="M39.75 47.7H34.45C31.5231 47.7 29.15 50.073 29.15 53V58.3C29.15 61.2269 31.5231 63.6 34.45 63.6H39.75C42.6769 63.6 45.05 61.2269 45.05 58.3V53C45.05 50.073 42.6769 47.7 39.75 47.7ZM34.45 58.3V53H39.75V58.3H34.45Z"
      />
      <path
        d="M58.3 47.7H53C50.073 47.7 47.7 50.073 47.7 53V58.3C47.7 61.2269 50.073 63.6 53 63.6H58.3C61.2269 63.6 63.6 61.2269 63.6 58.3V53C63.6 50.073 61.2269 47.7 58.3 47.7ZM53 58.3V53H58.3V58.3H53Z"
      />
      <path
        d="M21.2 66.25H15.9C12.9731 66.25 10.6 68.6231 10.6 71.55V76.85C10.6 79.7769 12.9731 82.15 15.9 82.15H21.2C24.1269 82.15 26.5 79.7769 26.5 76.85V71.55C26.5 68.6231 24.1269 66.25 21.2 66.25ZM15.9 76.85V71.55H21.2V76.85H15.9Z"
      />
      <path
        d="M39.75 66.25H34.45C31.5231 66.25 29.15 68.6231 29.15 71.55V76.85C29.15 79.7769 31.5231 82.15 34.45 82.15H39.75C42.6769 82.15 45.05 79.7769 45.05 76.85V71.55C45.05 68.6231 42.6769 66.25 39.75 66.25ZM34.45 76.85V71.55H39.75V76.85H34.45Z"
      />
      <path
        d="M58.3 66.25H53C50.073 66.25 47.7 68.6231 47.7 71.55V76.85C47.7 79.7769 50.073 82.15 53 82.15H58.3C61.2269 82.15 63.6 79.7769 63.6 76.85V71.55C63.6 68.6231 61.2269 66.25 58.3 66.25ZM53 76.85V71.55H58.3V76.85H53Z"
      />
      <path
        d="M156.593 84.5568C153.448 81.5533 148.498 81.5533 145.353 84.5568L99.9235 129.984C99.7036 130.207 99.5237 130.466 99.3932 130.751L92.9958 144.642C92.534 145.649 92.7473 146.838 93.5314 147.621C94.3145 148.405 95.5033 148.619 96.5103 148.157L110.402 141.759C110.686 141.629 110.946 141.45 111.168 141.229L156.593 95.7997C159.699 92.6956 159.699 87.6609 156.593 84.5568ZM107.752 137.143L100.809 140.341L104.007 133.398L137.879 99.5236L141.626 103.271L107.752 137.143ZM145.381 99.5121L141.634 95.7659L143.49 93.9106L147.237 97.6577L145.381 99.5121ZM152.855 92.0394L150.981 93.9133L147.234 90.1658L149.11 88.2923C150.157 87.2909 151.807 87.2909 152.855 88.2923C153.889 89.3274 153.889 91.0042 152.855 92.0394Z"
      />
      <path
        d="M63.6 118.256C54.1027 118.256 46.375 125.984 46.375 135.481C46.375 144.979 54.1027 152.706 63.6 152.706C73.0973 152.706 80.825 144.979 80.825 135.481C80.825 125.984 73.0973 118.256 63.6 118.256ZM63.6 150.553C55.2913 150.553 48.5281 143.792 48.5281 135.481C48.5281 127.17 55.2913 120.409 63.6 120.409C71.9087 120.409 78.6719 127.17 78.6719 135.481C78.6719 143.792 71.9087 150.553 63.6 150.553Z"
      />
      <path
        d="M71.7216 137.809C71.2283 137.488 70.5565 137.628 70.2336 138.125C68.1043 141.402 65.0962 141.941 63.6 141.986V136.321L67.09 135.449C67.6694 135.305 68.0182 134.721 67.8738 134.144C67.7297 133.565 67.1397 133.214 66.569 133.36L63.6 134.103V132.015L67.09 131.143C67.6694 130.998 68.0182 130.415 67.8738 129.838C67.7297 129.261 67.1397 128.906 66.569 129.054L63.6 129.797V127.945C63.6 127.351 63.1177 126.869 62.5234 126.869C61.9291 126.869 61.4468 127.351 61.4468 127.945V130.335L57.9565 131.207C57.3774 131.351 57.0286 131.937 57.1727 132.512C57.2933 133.003 57.7325 133.328 58.2172 133.328C58.3033 133.328 58.3914 133.317 58.4775 133.296L61.4468 132.553V134.641L57.9565 135.514C57.3774 135.658 57.0286 136.243 57.1727 136.818C57.2933 137.309 57.7325 137.634 58.2172 137.634C58.3033 137.634 58.3914 137.624 58.4775 137.602L61.4468 136.859V143.017C61.4468 143.549 61.8364 144.003 62.3618 144.083C62.4201 144.092 62.7818 144.141 63.3502 144.141C65.2019 144.141 69.2431 143.601 72.0379 139.296C72.3609 138.799 72.2211 138.132 71.7216 137.809Z"
      />
      <path
        d="M60.4531 95.0688C53.4213 95.0688 47.7 100.79 47.7 107.822C47.7 114.854 53.4213 120.575 60.4531 120.575C67.4849 120.575 73.2062 114.854 73.2062 107.822C73.2062 100.79 67.4849 95.0688 60.4531 95.0688ZM60.4531 118.981C54.2998 118.981 49.2939 113.975 49.2939 107.822C49.2939 101.669 54.2998 96.6628 60.4531 96.6628C66.6064 96.6628 71.6122 101.669 71.6122 107.822C71.6122 113.975 66.6064 118.981 60.4531 118.981Z"
      />
      <path
        d="M63.1919 111.994C62.5989 112.399 61.9451 112.604 61.2501 112.604C59.782 112.604 58.51 111.635 57.8197 110.213H60.4531C60.893 110.213 61.2501 109.856 61.2501 109.416C61.2501 108.976 60.893 108.619 60.4531 108.619H57.3317C57.295 108.359 57.2648 108.094 57.2648 107.822C57.2648 107.549 57.295 107.285 57.3317 107.025H61.2501C61.69 107.025 62.0471 106.668 62.0471 106.228C62.0471 105.788 61.69 105.431 61.2501 105.431H57.8197C58.51 104.009 59.782 103.039 61.2501 103.039C61.9451 103.039 62.5989 103.245 63.1919 103.65C63.5553 103.897 64.0528 103.806 64.2999 103.441C64.5483 103.078 64.4543 102.582 64.0909 102.333C63.2396 101.753 62.2578 101.445 61.2501 101.445C58.9148 101.445 56.9157 103.099 56.0852 105.431H54.8735C54.4336 105.431 54.0765 105.788 54.0765 106.228C54.0765 106.668 54.4336 107.025 54.8735 107.025H55.7407C55.7123 107.29 55.6705 107.549 55.6705 107.822C55.6705 108.094 55.7123 108.354 55.7407 108.619H54.8735C54.4336 108.619 54.0765 108.976 54.0765 109.416C54.0765 109.856 54.4336 110.213 54.8735 110.213H56.0852C56.9157 112.545 58.9148 114.198 61.2501 114.198C62.2578 114.198 63.2396 113.891 64.0909 113.31C64.4543 113.062 64.5483 112.566 64.2999 112.203C64.0528 111.839 63.5553 111.747 63.1919 111.994Z"
      />
    </SvgIcon>
  );
}
