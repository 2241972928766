/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function AccountIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 156 156">
      <path
        d="M55.125 61.25C72.0387 61.25 85.75 47.5387 85.75 30.625C85.75 13.7113 72.0387 0 55.125 0C38.2113 0 24.5 13.7113 24.5 30.625C24.5 47.5387 38.2113 61.25 55.125 61.25Z"
      />
      <path
        d="M70.6458 127.4C67.4546 121.875 68.0916 115.07 71.8891 110.25C68.0916 105.43 67.4546 98.6309 70.6458 93.1L75.6009 84.525C78.3265 79.8026 83.4102 76.8688 88.8676 76.8688C89.6149 76.8688 90.3499 76.9239 91.0788 77.028C91.2809 76.5258 91.5381 76.0541 91.7893 75.5764C88.4818 74.2717 84.9109 73.5 81.1562 73.5H29.0938C13.0524 73.5 0 86.5524 0 102.594V124.031C0 126.567 2.058 128.625 4.59375 128.625H71.3501L70.6458 127.4Z"
      />
      <path
        d="M132.931 113.123C133.053 112.173 133.219 111.23 133.219 110.25C133.219 109.264 133.047 108.327 132.931 107.377L138.449 103.2C139.681 102.269 140.024 100.566 139.252 99.225L134.297 90.65C133.525 89.3148 131.877 88.7574 130.456 89.3576L124.105 92.0343C122.555 90.8521 120.907 89.8109 119.088 89.0514L118.243 82.3077C118.047 80.7765 116.749 79.625 115.205 79.625H105.301C103.757 79.625 102.453 80.7765 102.263 82.3077L101.418 89.0514C99.5985 89.8109 97.9448 90.8583 96.4013 92.0343L90.0497 89.3576C88.6287 88.7574 86.981 89.3086 86.2093 90.65L81.2542 99.225C80.4824 100.56 80.8254 102.263 82.0565 103.2L87.5752 107.377C87.4527 108.327 87.2873 109.27 87.2873 110.25C87.2873 111.236 87.4588 112.173 87.5752 113.123L82.0565 117.3C80.8254 118.231 80.4824 119.934 81.2542 121.275L86.2093 129.856C86.981 131.191 88.6287 131.749 90.0497 131.149L96.4013 128.466C97.9509 129.648 99.5985 130.689 101.418 131.449L102.263 138.192C102.453 139.724 103.751 140.875 105.295 140.875H115.199C116.742 140.875 118.047 139.724 118.237 138.192L119.082 131.449C120.901 130.689 122.555 129.642 124.099 128.466L130.45 131.142C131.871 131.743 133.519 131.191 134.291 129.85L139.246 121.269C140.017 119.934 139.674 118.231 138.443 117.294L132.931 113.123ZM110.25 122.5C103.482 122.5 97.9999 117.018 97.9999 110.25C97.9999 103.482 103.482 98 110.25 98C117.018 98 122.5 103.482 122.5 110.25C122.5 117.018 117.018 122.5 110.25 122.5Z"
      />
    </SvgIcon>
  );
}
