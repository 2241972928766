import AccountIcon from './AccountIcon';
import AccountingIcon from './AccountingIcon';
import AdvisorIcon from './AdvisorIcon';
import AnnouncementsIcon from './AnnouncementsIcon';
import BrandIcon from './BrandIcon';
import BusinessIcon from './BusinessIcon';
import BusinessFeedbackIcon from './BusinessFeedbackIcon';
import CustomerFeedbackIcon from './CustomerFeedbackIcon';
import ExpenseIcon from './ExpenseIcon';
import FalseStatesIcon from './FalseStatesIcon';
import FeedbackIcon from './FeedbackIcon';
import GenelIcon from './GenelIcon';
import LogIcon from './LogIcon';
import PackagesModulesIcon from './PackagesModulesIcon';
import RevenueIcon from './RevenueIcon';
import SalesIcon from './SalesIcon';
import TechnicIcon from './TechnicIcon';
import VersionIcon from './VersionIcon';
import WaitingOrderIcon from './WaitingOrderIcon';
import WebSitesIcon from './WebSitesIcon';

export {
  AccountIcon,
  AccountingIcon,
  AdvisorIcon,
  AnnouncementsIcon,
  BrandIcon,
  BusinessIcon,
  BusinessFeedbackIcon,
  CustomerFeedbackIcon,
  ExpenseIcon,
  FalseStatesIcon,
  FeedbackIcon,
  GenelIcon,
  LogIcon,
  PackagesModulesIcon,
  RevenueIcon,
  SalesIcon,
  TechnicIcon,
  VersionIcon,
  WaitingOrderIcon,
  WebSitesIcon
};
