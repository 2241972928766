import * as React from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { CacheProvider, EmotionCache } from '@emotion/react';
import createEmotionCache from '@utils/createEmotionCache';
import { appWithTranslation } from 'next-i18next';
import OpsiyonProvider from '@providers/OpsiyonProvider';
import CssBaseline from '@mui/material/CssBaseline';
import Layout from '@components/Layout';
import Loading from '@modules/loading/components/Loading';
import Routes from '@src/routes';
import '@styles/globals.css';

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  Component: React.FC & { getLayout?: (page: any) => JSX.Element };
  emotionCache?: EmotionCache;
}

function MyApp(props: MyAppProps) {
  const {
    Component, emotionCache = clientSideEmotionCache, pageProps, router 
  } = props;
  const { getLayout } = Component;
  
  return (
    <React.StrictMode>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>OPS!YON</title>
          <meta charSet="utf-8" />
          <meta lang={router.locale} />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
          <meta name="theme-color" content="#ffffff" />
        </Head>

        <OpsiyonProvider>
          <CssBaseline />
          <Layout>
            {router.pathname !== Routes.login.path && router.pathname !== '/404' && router.pathname !== '/500' ? (
              getLayout ? getLayout(
                <>
                  <Loading />
                  <Component {...pageProps} />
                </>
              ) : (
                <>
                  <Loading />
                  <Component {...pageProps} />
                </>
              )
            ) : (
              <>
                <Loading />
                <Component {...pageProps} />
              </>
            )}
          </Layout>
        </OpsiyonProvider>
      </CacheProvider>
    </React.StrictMode>
  );
}

export default appWithTranslation(MyApp);
