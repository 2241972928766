import { combineReducers } from 'redux';

import loadingReducer from '@modules/loading/redux/loading-reducer';
import opsTableReducer from '@modules/OpsTable/redux/ops-table-reducer';
import accountReducer from '@modules/accounts/redux/account-reducer';
import accountingReducer from '@modules/accounting/redux/accounting-reducer';
import appReducer from './app-reducer';

const RootReducer = combineReducers({
  app: appReducer,
  loading: loadingReducer,
  opsTable: opsTableReducer,
  account: accountReducer,
  accounting: accountingReducer,
});

export default RootReducer;
