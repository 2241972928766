/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function LogIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 107 146">
      <path
        d="M30.5328 83.6679C28.7824 83.6679 27.3636 82.2491 27.3636 80.4987V47.6746C27.3636 45.9242 28.7824 44.5054 30.5328 44.5054H106.662V8.49704C106.662 4.05941 103.031 0.427979 98.5925 0.427979H32.969L0.0140381 33.3823V137.348C0.0140381 141.786 3.64482 145.417 8.0831 145.417H98.5931C103.031 145.417 106.662 141.787 106.662 137.348V83.6673H30.5328V83.6679Z"
      />
      <path
        d="M0.0140381 33.3823H24.8999C29.3375 33.3823 32.969 29.7515 32.969 25.3132V0.427979L0.0140381 33.3823Z"
      />
      <path
        d="M24.8999 35.0167H0.671814C-0.231141 35.0167 0.0373549 34.0001 0.037297 33.5001C0.037297 32.5972 0.037328 32.5001 0.671814 31.7475L17.0139 16.2018C19.7928 13.7499 24.2062 8.84605 27.8023 5.24992L31.3344 0.87767C32.0374 -2.41399e-05 33.5374 0 33.5374 0C33.5374 0 34.5374 -6.10948e-05 34.6036 0.87767V25.3137C34.6036 30.6641 30.2503 35.0167 24.8999 35.0167Z"
      />
      <path
        d="M91.5538 96.0729H34.499C33.596 96.0729 32.8643 95.3413 32.8643 94.4383C32.8643 93.5354 33.596 92.8037 34.499 92.8037H91.5538C92.4568 92.8037 93.1884 93.5354 93.1884 94.4383C93.1884 95.3413 92.4568 96.0729 91.5538 96.0729Z"
      />
      <path
        d="M28.4758 96.0729H15.6036C14.7007 96.0729 13.969 95.3413 13.969 94.4383C13.969 93.5354 14.7007 92.8037 15.6036 92.8037H28.4765C29.3794 92.8037 30.1111 93.5354 30.1111 94.4383C30.1111 95.3413 29.3788 96.0729 28.4758 96.0729Z"
      />
      <path
        d="M91.5538 103.841H57.7385C56.8355 103.841 56.1039 103.11 56.1039 102.207C56.1039 101.304 56.8355 100.572 57.7385 100.572H91.5538C92.4568 100.572 93.1884 101.304 93.1884 102.207C93.1884 103.11 92.4568 103.841 91.5538 103.841Z"
      />
      <path
        d="M51.4427 103.841H15.6036C14.7007 103.841 13.969 103.11 13.969 102.207C13.969 101.304 14.7007 100.572 15.6036 100.572H51.4427C52.3456 100.572 53.0773 101.304 53.0773 102.207C53.0773 103.11 52.3456 103.841 51.4427 103.841Z"
      />
      <path
        d="M91.5539 111.61H81.2114C80.3084 111.61 79.5768 110.878 79.5768 109.975C79.5768 109.072 80.3084 108.34 81.2114 108.34H91.5539C92.4568 108.34 93.1885 109.072 93.1885 109.975C93.1885 110.878 92.4568 111.61 91.5539 111.61Z"
      />
      <path
        d="M75.1019 111.61H15.6036C14.7007 111.61 13.969 110.878 13.969 109.975C13.969 109.072 14.7007 108.34 15.6036 108.34H75.1019C76.0049 108.34 76.7365 109.072 76.7365 109.975C76.7365 110.878 76.0049 111.61 75.1019 111.61Z"
      />
      <path
        d="M91.5539 119.378H43.2729C42.3699 119.378 41.6383 118.646 41.6383 117.743C41.6383 116.84 42.3699 116.109 43.2729 116.109H91.5539C92.4568 116.109 93.1885 116.84 93.1885 117.743C93.1885 118.646 92.4568 119.378 91.5539 119.378Z"
      />
      <path
        d="M36.9627 119.378H15.6036C14.7007 119.378 13.969 118.646 13.969 117.743C13.969 116.84 14.7007 116.109 15.6036 116.109H36.9627C37.8656 116.109 38.5973 116.84 38.5973 117.743C38.5973 118.646 37.8656 119.378 36.9627 119.378Z"
      />
      <path
        d="M91.5539 134.572H43.2729C42.3699 134.572 41.6383 133.84 41.6383 132.937C41.6383 132.034 42.3699 131.303 43.2729 131.303H91.5539C92.4568 131.303 93.1885 132.034 93.1885 132.937C93.1885 133.84 92.4568 134.572 91.5539 134.572Z"
      />
      <path
        d="M36.9627 134.572H15.6036C14.7007 134.572 13.969 133.84 13.969 132.937C13.969 132.034 14.7007 131.303 15.6036 131.303H36.9627C37.8656 131.303 38.5973 132.034 38.5973 132.937C38.5973 133.84 37.8656 134.572 36.9627 134.572Z"
      />
      <path
        d="M91.5538 127.146H57.7385C56.8355 127.146 56.1039 126.415 56.1039 125.512C56.1039 124.609 56.8355 123.877 57.7385 123.877H91.5538C92.4568 123.877 93.1884 124.609 93.1884 125.512C93.1884 126.415 92.4568 127.146 91.5538 127.146Z"
      />
      <path
        d="M51.4427 127.146H15.6036C14.7007 127.146 13.969 126.415 13.969 125.512C13.969 124.609 14.7007 123.877 15.6036 123.877H51.4427C52.3456 123.877 53.0773 124.609 53.0773 125.512C53.0773 126.415 52.3456 127.146 51.4427 127.146Z"
      />
      <path
        d="M54.8982 71.7783H47.9053V53.542C47.9053 53.3615 47.7589 53.2151 47.5784 53.2151H44.914C44.7336 53.2151 44.5871 53.3615 44.5871 53.542V74.1838C44.5871 74.3643 44.7336 74.5107 44.914 74.5107H54.8988C55.0793 74.5107 55.2258 74.3649 55.2251 74.1838V72.1052C55.2251 71.9248 55.0786 71.7783 54.8982 71.7783Z"
      />
      <path
        d="M76.1487 58.2315C75.2457 56.5766 73.9884 55.2683 72.4113 54.3438C70.8349 53.4205 69.0408 52.9524 67.0786 52.9524C65.1171 52.9524 63.3131 53.4199 61.7178 54.3431C60.1198 55.267 58.852 56.5753 57.9484 58.2315C57.048 59.8838 56.5916 61.7734 56.5916 63.8487C56.5916 65.924 57.048 67.8142 57.949 69.4658C58.8526 71.1233 60.1211 72.4316 61.7178 73.3549C63.3125 74.2774 65.1164 74.7456 67.0786 74.7456C69.0414 74.7456 70.8362 74.2768 72.4113 73.3542C73.9878 72.4303 75.2451 71.122 76.1487 69.4658C77.0503 67.8142 77.5067 65.9246 77.5067 63.8487C77.5067 61.7727 77.0503 59.8825 76.1487 58.2315ZM74.1296 63.8487C74.1296 65.4087 73.8223 66.791 73.2156 67.9581C72.6121 69.1173 71.7673 70.0242 70.7035 70.6532C69.6377 71.2835 68.4183 71.6032 67.0786 71.6032C65.718 71.6032 64.4881 71.2881 63.4223 70.6669C62.3592 70.0477 61.5151 69.1422 60.9129 67.9738C60.3055 66.7962 59.9975 65.4087 59.9975 63.8487C59.9975 62.2696 60.3048 60.8776 60.9122 59.7098C61.5151 58.5512 62.3592 57.6502 63.4223 57.0311C64.4887 56.4093 65.7186 56.0941 67.0786 56.0941C68.4183 56.0941 69.6391 56.4093 70.7055 57.0311C71.768 57.6502 72.6127 58.5519 73.2156 59.7098C73.8217 60.8783 74.1296 62.2703 74.1296 63.8487Z"
      />
      <path
        d="M99.9596 62.8188H89.5949C89.4145 62.8188 89.268 62.9652 89.268 63.1457V65.1367C89.268 65.3171 89.4145 65.4636 89.5949 65.4636H97.3384C97.1762 66.664 96.7996 67.7291 96.2157 68.634C95.5763 69.6253 94.7459 70.3903 93.7481 70.9075C92.7464 71.4266 91.6258 71.6901 90.4148 71.6901C88.9953 71.6901 87.7256 71.375 86.6389 70.7525C85.5575 70.1327 84.699 69.231 84.087 68.0731C83.471 66.9053 83.1585 65.4943 83.1585 63.8773C83.1585 62.2996 83.4658 60.9122 84.0732 59.7542C84.6754 58.6048 85.5149 57.7084 86.5689 57.0885C87.6255 56.4674 88.8397 56.1529 90.1801 56.1529C91.6689 56.1529 92.972 56.519 94.0541 57.2402C95.1336 57.9594 95.9143 58.9225 96.3759 60.1027C96.425 60.2283 96.5459 60.3106 96.6806 60.3106H99.4915C99.5935 60.3106 99.6896 60.2636 99.7511 60.1818C99.8132 60.1008 99.8334 59.9948 99.8066 59.8968C99.2221 57.7849 98.0726 56.0894 96.3896 54.8596C94.7099 53.633 92.6104 53.0105 90.1507 53.0105C88.1297 53.0105 86.31 53.4787 84.7434 54.4025C83.1749 55.3277 81.9371 56.6269 81.0636 58.2635C80.1933 59.8955 79.7513 61.7837 79.7513 63.878C79.7513 65.9729 80.1933 67.8619 81.0636 69.4925C81.9371 71.1298 83.1755 72.4244 84.7447 73.3391C86.31 74.2532 88.1388 74.7161 90.1801 74.7161C92.0423 74.7161 93.7318 74.3094 95.1984 73.5052C96.6649 72.7009 97.8484 71.5999 98.7141 70.2307C99.5758 68.8668 100.196 67.329 100.287 65.6055C100.319 64.9915 100.287 63.1457 100.287 63.1457C100.287 62.9652 100.14 62.8188 99.9596 62.8188Z"
      />
    </SvgIcon>
  );
}
