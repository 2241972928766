import {
  LoadingAction,
  LoadingActionType,
  OpsTableStateType,
} from './loading-actions';

const initialState: OpsTableStateType = false;

// eslint-disable-next-line default-param-last
export default function loadingReducer(state = initialState, { type, payload }: LoadingAction): OpsTableStateType {
  switch (type) {
    case LoadingActionType.SET_LOADING:
      return payload;
    default:
      return state;
  }
}
