import {
  OpsTableAction,
  OpsTableActionType,
  OpsTableStateType,
} from './ops-table-actions';

const initialState: OpsTableStateType = {
  showFilter: false,
  columns: [],
  initialColumns: [],
  selected: [],
  rows: [],
  any: {},
};

// eslint-disable-next-line default-param-last
export default function opsTableReducer(state = initialState, { type, payload }: OpsTableAction): OpsTableStateType {
  switch (type) {
    case OpsTableActionType.OPS_TABLE_SET_INITIAL_COLUMNS:
    case OpsTableActionType.OPS_TABLE_SET_COLUMNS:
    case OpsTableActionType.OPS_TABLE_SHOW_FILTER:
    case OpsTableActionType.OPS_TABLE_ROW_SELECT:
    case OpsTableActionType.OPS_TABLE_SET_ROWS:
    case OpsTableActionType.OPS_TABLE_CLEAR:
      return { ...state, ...payload };
    case OpsTableActionType.OPS_TABLE_SET_ROW: {
      const s = { ...state };
      if (s.rows === undefined) return s;
      s.rows[payload.any.index] = payload.any.data;
      return s;
    }
    case OpsTableActionType.OPS_TABLE_DELETE_ROW: {
      const s = { ...state };
      if (s.rows === undefined) return s;
      s.rows.splice(payload.any.index, 1);
      return s;
    }
    case OpsTableActionType.OPS_TABLE_ROW_PUSH: {
      const s = { ...state };
      if (s.rows === undefined) return s;
      s.rows.push(payload.any.data);
      return s;
    }
    default:
      return state;
  }
}
