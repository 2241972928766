import { OpsTableColumn } from '../components/OpsTable.d';

export enum OpsTableActionType {
  OPS_TABLE_SET_INITIAL_COLUMNS = 'OPS_TABLE_SET_INITIAL_COLUMNS',
  OPS_TABLE_SET_COLUMNS = 'OPS_TABLE_SET_COLUMNS',
  OPS_TABLE_SHOW_FILTER = 'OPS_TABLE_SHOW_FILTER',
  OPS_TABLE_SET_ROW = 'OPS_TABLE_SET_ROW',
  OPS_TABLE_SET_ROWS = 'OPS_TABLE_SET_ROWS',
  OPS_TABLE_ROW_PUSH = 'OPS_TABLE_ROW_PUSH',
  OPS_TABLE_DELETE_ROW = 'OPS_TABLE_DELETE_ROW',
  OPS_TABLE_ROW_SELECT = 'OPS_TABLE_ROW_SELECT',
  OPS_TABLE_CLEAR = 'OPS_TABLE_CLEAR',
}

export type OpsTableStateType = {
  showFilter?: boolean;
  columns?: Array<OpsTableColumn>;
  initialColumns?: Array<OpsTableColumn>;
  selected?: Array<number>;
  rows?: Array<any>;
  any?: any;
};

export type OpsTableAction = {
  type: OpsTableActionType;
  payload: OpsTableStateType;
};

export function setOpsTableInitialColumnsAction(
  data: Array<OpsTableColumn>
): OpsTableAction {
  return {
    type: OpsTableActionType.OPS_TABLE_SET_INITIAL_COLUMNS,
    payload: { initialColumns: data },
  };
}

export function setOpsTableColumnsAction(
  data: Array<OpsTableColumn>
): OpsTableAction {
  return {
    type: OpsTableActionType.OPS_TABLE_SET_COLUMNS,
    payload: { columns: data },
  };
}

export function setOpsTableShowFilterAction(data: boolean): OpsTableAction {
  return {
    type: OpsTableActionType.OPS_TABLE_SHOW_FILTER,
    payload: { showFilter: data },
  };
}

export function setOpsTableRowSelectedAction(
  data: Array<number>
): OpsTableAction {
  return {
    type: OpsTableActionType.OPS_TABLE_ROW_SELECT,
    payload: { selected: data },
  };
}

export function setOpsTableRowAction(index: number, data: any): OpsTableAction {
  return {
    type: OpsTableActionType.OPS_TABLE_SET_ROW,
    payload: { any: { index, data } },
  };
}

export function setOpsTableRowsAction(data: Array<any>): OpsTableAction {
  return {
    type: OpsTableActionType.OPS_TABLE_SET_ROWS,
    payload: { rows: data },
  };
}

export function setOpsTableRowPushAction(data: Array<any>): OpsTableAction {
  return {
    type: OpsTableActionType.OPS_TABLE_ROW_PUSH,
    payload: { any: { data } },
  };
}

export function setOpsTableDeleteRowAction(index: number): OpsTableAction {
  return {
    type: OpsTableActionType.OPS_TABLE_DELETE_ROW,
    payload: { any: { index } },
  };
}

export function setOpsTableClearAction(): OpsTableAction {
  return {
    type: OpsTableActionType.OPS_TABLE_CLEAR,
    payload: {
      showFilter: false,
      columns: [],
      initialColumns: [],
      selected: [],
      rows: [],
      any: undefined,
    },
  };
}
