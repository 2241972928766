/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

type SidebarStatus = 'open' | 'close';

interface ISidebarContext {
  status: SidebarStatus;
  toggleSidebar: () => void;
}

const SidebarContext = React.createContext<ISidebarContext>({
  status: 'open',
  toggleSidebar: () => {}
});

export default function SidebarProvider({ children }: any) {
  const [status, setStatus] = React.useState<SidebarStatus>('open');
  const sidebarStatus = React.useMemo(() => ({
    toggleSidebar: () => {
      setStatus((prevStatus) => (prevStatus === 'open' ? 'close' : 'open'));
    },
    status
  }), [status]);
  return (
    <SidebarContext.Provider value={sidebarStatus}>
      {children}
    </SidebarContext.Provider>
  );
}

export const useSidebar = () => React.useContext(SidebarContext);
