/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function WaitingOrderIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 564 557" {...props}>
      <path d="M138.36 473.252H84.8041C52.8226 473.252 26.7993 447.334 26.7993 415.482V84.569C26.7993 52.7182 52.8226 26.7999 84.8041 26.7999C92.202 26.7999 98.2038 20.7982 98.2038 13.4003C98.2038 6.00238 92.202 0 84.8041 0C38.0441 0 0 37.9403 0 84.569V415.482C0 462.112 38.0448 500.051 84.8041 500.051H138.36C145.758 500.051 151.759 494.049 151.759 486.651C151.759 479.254 145.758 473.252 138.36 473.252Z" />
      <path d="M416.851 26.7999C448.824 26.7999 474.839 52.7182 474.839 84.569V133.736C474.839 141.134 480.841 147.136 488.238 147.136C495.636 147.136 501.638 141.134 501.638 133.736V84.569C501.638 37.9403 463.602 0 416.851 0C409.453 0 403.452 6.00238 403.452 13.4003C403.452 20.7982 409.453 26.7999 416.851 26.7999Z" />
      <path d="M138.36 26.7999H182.127C189.525 26.7999 195.527 20.7982 195.527 13.4003C195.527 6.00238 189.525 0 182.127 0H138.36C130.962 0 124.96 6.00238 124.96 13.4003C124.96 20.7982 130.962 26.7999 138.36 26.7999Z" />
      <path d="M231.496 26.7999H275.264C282.662 26.7999 288.663 20.7982 288.663 13.4003C288.663 6.00238 282.662 0 275.264 0H231.496C224.098 0 218.096 6.00238 218.096 13.4003C218.096 20.7982 224.098 26.7999 231.496 26.7999Z" />
      <path d="M324.622 26.7999H368.398C375.796 26.7999 381.798 20.7982 381.798 13.4003C381.798 6.00238 375.796 0 368.398 0H324.622C317.224 0 311.223 6.00238 311.223 13.4003C311.223 20.7982 317.224 26.7999 324.622 26.7999Z" />
      <path d="M366.75 165.735C258.321 165.735 170.446 253.323 170.446 361.359C170.446 469.412 258.321 557 366.75 557C475.144 557 563.037 469.412 563.037 361.359C563.037 253.323 475.144 165.735 366.75 165.735ZM502.52 385.699H356.666C355.157 386.86 353.35 387.67 351.3 387.67C348.718 387.67 346.432 386.545 344.809 384.792C341.782 383.362 339.653 380.335 339.653 376.766C339.653 374.254 340.701 372.002 342.366 370.381V274.784C342.366 269.846 346.362 265.85 351.299 265.85C356.237 265.85 360.232 269.846 360.232 274.784V367.833H502.519C507.456 367.833 511.451 371.828 511.451 376.765C511.452 381.703 507.457 385.699 502.52 385.699Z" />
      <path d="M124.079 135.289H231.504C238.902 135.289 244.904 129.287 244.904 121.889C244.904 114.491 238.902 108.49 231.504 108.49H124.079C116.681 108.49 110.679 114.491 110.679 121.889C110.679 129.287 116.681 135.289 124.079 135.289Z" />
      <path d="M181.517 176.448C181.517 169.051 175.515 163.049 168.117 163.049H124.079C116.682 163.049 110.679 169.051 110.679 176.448C110.679 183.846 116.682 189.849 124.079 189.849H168.117C175.514 189.849 181.517 183.846 181.517 176.448Z" />
    </SvgIcon>
  );
}
