import store from '@redux/store';
import { i18n } from 'next-i18next';
import { ApiResponse } from '@lib/api.d';
import ErrorMiddleware from '@middlewares/errorMiddleware';
import { showToast } from '@components/utils/SnackbarUtils';
import { setLoadingAction } from '@modules/loading/redux/loading-actions';

import Api from '@lib/api';
import ILoginDTO from '../types/ILoginDTO';
import IManagementUserDetailDTO from '../types/IManagementUserDetailDTO';
import IGeneralLoginResultDTO from '../types/IGeneralLoginResultDTO';

/**
 * Login method for management users. This endpoint is accessible for any requests.
 */
export async function Login(data: ILoginDTO) {
  return apiFetch<IGeneralLoginResultDTO>(`${process.env.NEXT_PUBLIC_BASE_PATH}api/login`, 'POST', data);
}

/**
 * Logout process for current logged in user.
 */
export async function Logout() {
  return apiFetch(`${process.env.NEXT_PUBLIC_BASE_PATH}api/logout`, 'POST');
}

/**
 * Gets logged user's information.
 */
export function LoggedUser() {
  return Api.Get<IManagementUserDetailDTO>({
    url: '/Account/Info/LoggedUser',
    successMessaging: false,
    errorMessaging: false,
  });
}

async function apiFetch<Response>(url: string, method: string, data?: any): Promise<ApiResponse<Response>> {
  const controller = new AbortController();
  try {
    store.dispatch(setLoadingAction(true));

    const response = await fetch(url, {
      method,
      signal: controller.signal,
      body: JSON.stringify(data),
    });

    const result = await response.json();
    if (response.ok && result.statusCode !== 200) {
      ErrorMiddleware(result)?.();
    }
    return result as ApiResponse<Response>;
  } catch (error: any) {
    if (process.env.NODE_ENV !== 'production') console.log(error);
    if (!error.response?.status) showToast(i18n?.t('helperTexts.networkError') || '', 'error');
    else {
      const errorCallBack = ErrorMiddleware(error);
      errorCallBack?.();
    }
    return error;
  } finally {
    store.dispatch(setLoadingAction(false));
    controller.abort();
  }
}
