/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function ExpenseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 464 471">
      <path d="M245.092 0L189.174 84.482L218.891 88.215C209.671 118.345 207.796 139.093 210.006 180.335C224.144 178.105 235.566 177.31 250.592 181.725C240.715 144.885 241.748 132.298 245.712 91.957L278.334 94.234L245.092 0ZM463.574 2.21L355.214 32.24L376.129 58.215C326.617 89.234 295.798 113.763 271.389 181.379C284.59 181.227 299.487 184.3 315.563 190.383C321.291 145.717 349.303 114.243 394.865 81.465L414.848 106.281L463.574 2.211V2.21ZM0 4.52L65.879 107.005L84.963 78.294C120.517 110.734 143.108 154.624 142.271 185.724C160.839 177.028 172.198 176.197 192.006 181.946C183.901 150.743 148.429 73.224 100.367 52.843L116.937 26.806L0.000999451 4.519L0 4.52ZM276.117 219.187C270.837 219.307 265.907 221.602 259.18 228.781L252.615 235.75L245.803 229.03C238.416 221.75 232.587 219.74 226.678 220C220.77 220.26 213.823 223.367 206.053 229.656L199.836 234.686L193.93 229.312C185.03 221.26 177.445 218.873 170.18 219.248C164.892 219.522 159.405 221.514 153.93 224.998L194.896 298.688C210.35 308.139 241.93 311.694 263.646 300.75L303.24 227.406C295.73 224.344 288.98 221.204 283.146 220C281.034 219.563 279.076 219.244 277.178 219.187C276.824 219.177 276.468 219.179 276.118 219.187H276.117ZM186.147 315.375V315.377C168.112 328.119 153.631 350.094 148.022 382.281C142.587 413.477 151.151 434.547 166.305 448.906C181.46 463.266 204.207 470.642 227.305 470.342C250.405 470.042 273.441 462.032 288.93 447.406C304.42 432.779 313.179 411.981 308.211 382.219C303.074 351.462 289.811 330.071 273.021 317.125C244.539 332.181 208.926 328.981 186.146 315.375H186.147Z" />

    </SvgIcon>
  );
}
