/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function CustomerFeedbackIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 339 379">
      <path d="M241.27 0H333.821C336.582 0 338.821 2.23858 338.821 5V51.9046C338.821 54.666 336.582 56.9046 333.821 56.9046H246.27C243.509 56.9046 241.27 54.666 241.27 51.9046V0Z" />
      <path d="M311.11 53.1381C304.034 53.1381 297.299 50.9227 291.679 46.8285C288.139 50.7037 283.059 53.1381 277.422 53.1381H264.519C253.829 53.1381 245.164 44.4343 245.164 33.6973V0H169.036C165.473 0 162.585 2.90143 162.585 6.48026V138.044C162.585 140.324 163.675 142.513 165.596 143.728C166.652 144.396 167.846 144.726 169.036 144.726C170.287 144.726 171.536 144.36 172.615 143.638L196.796 127.445H332.475C336.038 127.445 338.926 124.544 338.926 120.965V53.1381H311.11ZM194.842 32.4013H231.401C234.964 32.4013 237.852 35.3027 237.852 38.8816C237.852 42.4604 234.964 45.3618 231.401 45.3618H194.842C191.279 45.3618 188.391 42.4604 188.391 38.8816C188.391 35.3027 191.279 32.4013 194.842 32.4013ZM306.669 103.684H194.842C191.279 103.684 188.391 100.783 188.391 97.2039C188.391 93.6251 191.279 90.7236 194.842 90.7236H306.669C310.232 90.7236 313.12 93.6251 313.12 97.2039C313.12 100.783 310.232 103.684 306.669 103.684ZM306.669 74.523H194.842C191.279 74.523 188.391 71.6216 188.391 68.0427C188.391 64.4639 191.279 61.5625 194.842 61.5625H306.669C310.232 61.5625 313.12 64.4639 313.12 68.0427C313.12 71.6216 310.232 74.523 306.669 74.523Z" />
      <path d="M105.68 132.39C67.2508 132.39 34.8396 164.801 34.8396 203.231C34.8396 241.66 67.2508 274.071 105.68 274.071C144.109 274.071 175.359 241.66 175.359 203.231C175.359 164.801 144.109 132.39 105.68 132.39Z" />
      <path d="M183.015 274.071C163.87 295.299 136.45 308.911 105.68 308.911C74.9097 308.911 46.3285 295.299 27.1841 274.071C10.4704 292.605 0 316.884 0 343.75V361.17C0 370.799 7.79129 378.59 17.4198 378.59H192.779C202.407 378.59 210.199 370.799 210.199 361.17V343.75C210.199 316.884 199.728 292.605 183.015 274.071Z" />
    </SvgIcon>
  );
}
