export enum AccountingActions {
  SET_ACCOUNTING_BUSSINESSES = 'SET_ACCOUNTING_BUSSINESSES',
}

export type AccountingStateType = {
  businesses?: Array<{ label: string, value: string }>;
};

export type AccountingAction = {
  type: AccountingActions;
  payload: AccountingStateType;
};

export function setAccountingBusinesses(businesses: Array<{ label: string, value: string }>): AccountingAction {
  return {
    type: AccountingActions.SET_ACCOUNTING_BUSSINESSES,
    payload: { businesses },
  };
}
