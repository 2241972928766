import { setAppLoggedUserAction } from '@redux/app-actions';
import { dispatcher } from '@redux/store';
import { LoggedUser } from '../api';

export default function LoggedUserControl() {
  LoggedUser().then((response) => {
    if (response.result !== undefined) {
      dispatcher(setAppLoggedUserAction(response.result));
    }
  });
}
