/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { ThemeProvider, createTheme, ThemeOptions } from '@mui/material/styles';

type ColorMode = string | 'light' | 'dark';

interface IColorModeContext {
  mode: ColorMode | undefined;
  toggleColorMode: () => void;
}

export const ColorModeContext = React.createContext<IColorModeContext>({
  toggleColorMode: () => {},
  mode: 'light'
});

export default function ColorModeContextProvider({ children }: any) {
  const [mode, setMode] = React.useState<ColorMode>();
  React.useEffect(() => {
    const getCurrentTheme = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
    let temp = window.localStorage.getItem('mode');
    if (temp !== 'light' && temp !== 'dark') {
      temp = getCurrentTheme() ? 'dark' : 'light';
      window.localStorage.setItem('mode', temp);
    }
    setMode(temp);
  }, []);

  const colorMode = React.useMemo(() => ({
    toggleColorMode: () => {
      const nextMode = (mode === 'light' ? 'dark' : 'light');
      setMode(nextMode);
      window.localStorage.setItem('mode', nextMode);
    },
    mode
  }), [mode]);

  const theme = React.useMemo(() => createTheme({
    palette: {
      mode,
      ...(mode === 'light' ? {
        primary: {
          main: '#9F414B',
          contrastText: '#fff',
        },
        secondary: {
          main: '#c9c9c9',
          contrastText: '#fff'
        },
        text: {
          primary: '#000'
        }
      } : {
        primary: {
          main: '#9F414B',
          contrastText: '#fff',
        },
        secondary: {
          main: '#121212',
          light: '#3f3f3f',
          contrastText: '#fff'
        },
        background: {
          default: '#292929',
          paper: '#1F1F1F'
        }
      })
    },
    typography: {
      fontFamily: 'Open Sans, sans-serif',
      h3: {
        fontSize: 27
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        /* cyrillic-ext */
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-stretch: 100%;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4taVIGxA.woff2) format('woff2');
          unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
        }
        /* cyrillic */
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-stretch: 100%;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVIGxA.woff2) format('woff2');
          unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }
        /* greek-ext */
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-stretch: 100%;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4saVIGxA.woff2) format('woff2');
          unicode-range: U+1F00-1FFF;
        }
        /* greek */
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-stretch: 100%;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4jaVIGxA.woff2) format('woff2');
          unicode-range: U+0370-03FF;
        }
        /* hebrew */
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-stretch: 100%;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVIGxA.woff2) format('woff2');
          unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
        }
        /* vietnamese */
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-stretch: 100%;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4vaVIGxA.woff2) format('woff2');
          unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }
        /* latin-ext */
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-stretch: 100%;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4uaVIGxA.woff2) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        /* latin */
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-stretch: 100%;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/opensans/v28/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }`,
      },
    },
  } as ThemeOptions), [mode]);
  if (mode === undefined) return null;
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export const useColorMode = () => React.useContext(ColorModeContext);
