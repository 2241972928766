import { Route } from './routes';

const Routes: Route = {
  // const
  login: { path: '/login', middleware: 'noAuth' },
  index: { path: '/', middleware: 'auth', permission: 'Admin|Manager' },
  // businesses
  businesses: { path: '/businesses', middleware: 'auth', permission: 'Admin|Manager|TeamLead|Tech|Support' },
  businessesDetail: { path: '/businesses/detail', middleware: 'auth', permission: 'Admin|Manager|TeamLead|Tech|Support' },
  businessesAdd: { path: '/businesses/add', middleware: 'auth', permission: 'Admin|Manager' },
  // accounting
  expense: { path: '/accounting', middleware: 'auth', permission: 'Admin|Manager' },
  revenue: { path: '/accounting/revenue', middleware: 'auth', permission: 'Admin|Manager' },
  // registrar
  orders: { path: '/registrar', middleware: 'auth', permission: 'Admin|Manager|Support' },
  reOrders: { path: '/registrar/reOrders', middleware: 'auth', permission: 'Admin|Manager' },
  subBusinessApplication: { path: '/registrar/subBusinessApplication', middleware: 'auth', permission: 'Admin|Manager|Support' },
  demoRequest: { path: '/registrar/demoRequest', middleware: 'auth', permission: 'Admin|Manager|Support' },
  orderDetail: { path: '/registrar/orders/detail', middleware: 'auth', permission: 'Admin|Manager|Support' },
  subBusinessDetail: { path: '/registrar/sub-business/detail', middleware: 'auth', permission: 'Admin|Manager|Support' },
  demoDetail: { path: '/registrar/demo/detail', middleware: 'auth', permission: 'Admin|Manager|Support' },
  // feedback
  businessFedbacks: { path: '/feedbacks', middleware: 'auth', permission: 'Admin|Manager|Support' },
  customerFeedbacks: { path: '/feedbacks/customer', middleware: 'auth', permission: 'Admin|Manager|Support' },
  // tickets
  tickets: { path: '/tickets', middleware: 'auth', permission: 'Admin|Manager|TeamLead|Tech|Support' },
  ticketDetail: { path: '/tickets/detail', middleware: 'auth', permission: 'Admin|Manager|TeamLead|Tech|Support' },
  // site-data
  siteDataForms: { path: '/site-data', middleware: 'auth', permission: 'Admin|Manager|Support' },
  siteDataMedias: { path: '/site-data/medias', middleware: 'auth', permission: 'Admin|Manager' },
  // announcements
  announcements: { path: '/announcements', middleware: 'auth', permission: 'Admin|Manager' },
  // opsiyon
  opsiyonModules: { path: '/opsiyon', middleware: 'auth', permission: 'Admin|Manager' },
  opsiyonPackages: { path: '/opsiyon/packages', middleware: 'auth', permission: 'Admin|Manager' },
  opsiyonPackageAdd: { path: '/opsiyon/packages/add', middleware: 'auth', permission: 'Admin|Manager' },
  opsiyonPackageEdit: { path: '/opsiyon/packages/detail', middleware: 'auth', permission: 'Admin|Manager' },
  // accounts
  accountUsers: { path: '/accounts', middleware: 'auth', permission: 'Admin|Manager' },
  accountRoles: { path: '/accounts/roles', middleware: 'auth', permission: 'Admin|Manager' },
  // technic
  technicErrors: { path: '/technic', middleware: 'auth', permission: 'Admin|Tech|TeamLead' },
  technicTenancy: { path: '/technic/tenancy', middleware: 'auth', permission: 'Admin|Tech|TeamLead' },
  technicDetail: { path: '/technic/detail', middleware: 'auth', permission: 'Admin|Tech|TeamLead' },
  // activeLog
  activeLog: { path: '/user-activity-logs', middleware: 'auth', permission: 'Admin|Manager' },
  /// api
  apiLogin: { path: '/api/login', middleware: 'noAuth' },
  apiLogout: { path: '/api/logout', middleware: 'auth' },
  apiAuth: { path: '/api/auth', middleware: 'auth' },
};

export default Routes;
