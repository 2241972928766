export enum LoadingActionType {
  SET_LOADING = 'set_loading',
}

export type OpsTableStateType = boolean;

export type LoadingAction = {
  type: LoadingActionType;
  payload: OpsTableStateType;
};

export function setLoadingAction(data: OpsTableStateType): LoadingAction {
  return { type: LoadingActionType.SET_LOADING, payload: data };
}
