/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function BrandIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 374 374">
      <path d="M15.2123 121.408L229.327 365.947L358.788 252.593L144.673 8.05369L42.1586 21.5776L15.2123 121.408Z" />
      <circle cx="88.4527" cy="71.6229" r="27.5267" />
    </SvgIcon>
  );
}
