/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function SalesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 162 162">
      <path
        d="M162 121.5V45.9C161.991 38.448 155.952 32.4088 148.5 32.4H118.8V0H113.4V62.1C113.4 63.5911 114.609 64.8 116.1 64.8H125.253L81 91.3518L36.747 64.8H45.9C47.3911 64.8 48.6 63.5911 48.6 62.1V0H43.2V32.4H13.5C6.048 32.4088 0.008775 38.448 0 45.9V121.5C0.008775 128.952 6.048 134.991 13.5 135H57.7314L52.3314 145.8H2.7C1.20892 145.8 0 147.009 0 148.5C0 162 14.2263 162 18.9 162H143.1C147.774 162 162 162 162 148.5C162 147.009 160.791 145.8 159.3 145.8H109.669L104.269 135H148.5C155.952 134.991 161.991 128.952 162 121.5ZM43.2 59.4H27C25.5089 59.4003 24.3003 60.6096 24.301 62.101C24.3013 63.0484 24.7978 63.9259 25.6098 64.4142L79.6098 96.8142C80.4654 97.3282 81.5353 97.3282 82.3908 96.8142L136.391 64.4142C137.669 63.6457 138.082 61.9869 137.314 60.7088C136.826 59.8971 135.948 59.4003 135 59.4H118.8V48.6H145.8V113.4H16.2V48.6H43.2V59.4ZM156.273 151.2C155.331 154.524 152.072 156.6 143.1 156.6H18.9C9.9279 156.6 6.669 154.524 5.7267 151.2H156.273ZM58.3686 145.8L63.7686 135H98.2314L103.631 145.8H58.3686ZM13.5 129.6C9.02644 129.6 5.4 125.974 5.4 121.5V45.9C5.4 41.4264 9.02644 37.8 13.5 37.8H43.2V43.2H13.5C12.0089 43.2 10.8 44.4089 10.8 45.9V116.1C10.8 117.591 12.0089 118.8 13.5 118.8H148.5C149.991 118.8 151.2 117.591 151.2 116.1V45.9C151.2 44.4089 149.991 43.2 148.5 43.2H118.8V37.8H148.5C152.974 37.8 156.6 41.4264 156.6 45.9V121.5C156.6 125.974 152.974 129.6 148.5 129.6H13.5Z"
      />
    </SvgIcon>
  );
}
