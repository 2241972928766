/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function BusinessIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 164 154">
      <path
        d="M44.7554 57.6067H72.4872V27.3711H49.3482L44.7554 57.6067Z"
      />
      <path
        d="M49.9297 126.529V66.6301H9.78125V126.529H49.9297ZM44.115 90.621V99.9934H35.0915V90.621H44.115Z"
      />
      <path
        d="M81.5105 57.6067H109.245L104.652 27.3711H81.5105V57.6067Z"
      />
      <path
        d="M35.6285 57.6067L40.2212 27.3711H17.3196L3.68689 57.6067H35.6285Z"
      />
      <path
        d="M58.9531 126.529H79.6565V111.051H71.0842V102.027H97.2522V111.051H88.68V126.529H114.998V111.051H106.426V102.027H132.594V111.051H124.022V126.529H144.219V66.6301H58.9531V126.529Z"
      />
      <path
        d="M27.713 0H126.287V18.3477H27.713V0Z"
      />
      <path d="M0 136.31H154V154H0V136.31Z" />
      <path
        d="M113.779 27.3711L118.371 57.6067H150.313L136.68 27.3711H113.779Z"
      />
    </SvgIcon>
  );
}
