import {
  AccountIcon,
  AccountingIcon,
  AdvisorIcon,
  AnnouncementsIcon,
  BusinessIcon,
  FeedbackIcon,
  LogIcon,
  PackagesModulesIcon,
  SalesIcon,
  TechnicIcon,
  WebSitesIcon
} from '@assets/icons';
import Routes from '@src/routes';

export default [
  {
    moduleName: 'business',
    route: Routes.businesses,
    icon: <BusinessIcon />,
    show: true,
    disabled: false,
  },
  {
    moduleName: 'accounting',
    route: Routes.expense,
    icon: <AccountingIcon />,
    show: true,
    disabled: false,
  },
  {
    moduleName: 'sales',
    route: Routes.orders,
    icon: <SalesIcon />,
    show: true,
    disabled: false,
  },
  {
    moduleName: 'feedback',
    route: Routes.businessFedbacks,
    icon: <FeedbackIcon />,
    show: true,
    disabled: false,
  },
  {
    moduleName: 'ticket',
    route: Routes.tickets,
    icon: <AdvisorIcon />,
    show: true,
    disabled: false,
  },
  {
    moduleName: 'website',
    route: Routes.siteDataForms,
    icon: <WebSitesIcon />,
    show: true,
    disabled: false,
  },
  {
    moduleName: 'announcements',
    route: Routes.announcements,
    icon: <AnnouncementsIcon />,
    show: true,
    disabled: false,
  },
  {
    moduleName: 'modules-packages',
    route: Routes.opsiyonModules,
    icon: <PackagesModulesIcon />,
    show: true,
    disabled: false,
  },
  {
    moduleName: 'account',
    route: Routes.accountUsers,
    icon: <AccountIcon />,
    show: true,
    disabled: false,
  },
  {
    moduleName: 'technic',
    route: Routes.technicErrors,
    icon: <TechnicIcon />,
    show: true,
    disabled: false,
  },
  {
    moduleName: 'activeLog',
    route: Routes.activeLog,
    icon: <LogIcon />,
    show: true,
    disabled: false,
  },
];
