import { Box } from '@mui/material';
import React from 'react';
import SidebarProvider from '../core/providers/SidebarContext';

import HeaderAppBar from './HeaderAppBar';
import Sidebar from './Sidebar';

export default function Layout({ children }: any) {
  return (
    <SidebarProvider>
      <HeaderAppBar />
      <Box sx={{ height: 'calc(100% - 64px)', display: 'flex' }}>
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100%',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          {children}
        </Box>
      </Box>
    </SidebarProvider>
  );
}
