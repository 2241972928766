/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function PackagesModulesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 177 176">
      <path
        d="M29.3363 88C29.3363 81.4381 24.9744 75.9352 19.0197 74.0667C19.9467 69.4525 21.3107 64.9352 23.1381 60.6115C23.7688 59.1184 23.0736 57.3965 21.5805 56.7659C20.0845 56.1323 18.3656 56.8304 17.7349 58.3235C15.6757 63.1899 14.1651 68.2821 13.1619 73.4829C5.7904 74.2485 0 80.4261 0 87.9971C0 95.568 5.7904 101.746 13.1619 102.511C14.1651 107.712 15.6757 112.807 17.7349 117.671C18.2072 118.791 19.2955 119.46 20.4365 119.46C20.8179 119.46 21.2051 119.387 21.5776 119.228C23.0707 118.598 23.7688 116.876 23.1352 115.383C21.3077 111.059 19.9437 106.542 19.0168 101.927C24.9744 100.065 29.3363 94.5619 29.3363 88Z"
      />
      <path
        d="M176.003 88C176.003 80.4291 170.215 74.2515 162.844 73.4859C161.841 68.2851 160.327 63.1899 158.268 58.3264C157.637 56.8333 155.918 56.1352 154.422 56.7688C152.929 57.3995 152.231 59.1213 152.865 60.6144C154.695 64.9381 156.056 69.4555 156.983 74.0696C151.029 75.9381 146.667 81.4411 146.667 88.0029C146.667 94.5648 151.029 100.068 156.983 101.936C156.056 106.55 154.692 111.068 152.865 115.391C152.234 116.885 152.929 118.606 154.422 119.237C154.795 119.395 155.182 119.469 155.563 119.469C156.707 119.469 157.793 118.797 158.265 117.679C160.324 112.816 161.835 107.721 162.841 102.52C170.215 101.749 176.003 95.5709 176.003 88Z"
      />
      <path
        d="M143.745 45.012C144.323 45.76 145.191 46.1531 146.068 46.1531C146.696 46.1531 147.327 45.9536 147.86 45.54C149.142 44.5515 149.38 42.7093 148.388 41.4275C144.393 36.2531 139.738 31.6008 134.558 27.5997C133.276 26.6112 131.437 26.8459 130.442 28.1277C129.454 29.4125 129.689 31.2517 130.97 32.2403C135.758 35.9363 140.055 40.2336 143.745 45.012Z"
      />
      <path
        d="M115.391 152.862C111.071 154.689 106.55 156.053 101.936 156.983C100.068 151.028 94.5648 146.667 88.0029 146.667C81.4411 146.667 75.9381 151.028 74.0696 156.983C69.4555 156.056 64.9352 154.692 60.6144 152.862C59.1184 152.234 57.3994 152.923 56.7688 154.419C56.1381 155.912 56.8333 157.634 58.3264 158.265C63.1899 160.321 68.285 161.835 73.4858 162.841C74.2514 170.212 80.429 176 88 176C95.5709 176 101.749 170.212 102.514 162.841C107.715 161.835 112.81 160.321 117.674 158.265C119.167 157.634 119.865 155.912 119.231 154.419C118.603 152.926 116.885 152.234 115.391 152.862Z"
      />
      <path
        d="M143.763 130.965C140.07 135.752 135.772 140.052 130.991 143.742C129.709 144.731 129.471 146.573 130.463 147.855C131.041 148.603 131.909 148.996 132.786 148.996C133.414 148.996 134.045 148.796 134.578 148.383C139.756 144.388 144.408 139.732 148.406 134.549C149.395 133.264 149.16 131.425 147.875 130.434C146.59 129.451 144.748 129.683 143.763 130.965Z"
      />
      <path
        d="M32.2608 130.988C31.2693 129.703 29.4272 129.469 28.1453 130.46C26.8635 131.449 26.6259 133.291 27.6173 134.573C31.6125 139.747 36.2677 144.399 41.448 148.4C41.9819 148.814 42.6125 149.013 43.2403 149.013C44.1173 149.013 44.9856 148.62 45.5635 147.872C46.552 146.588 46.3173 144.748 45.0355 143.76C40.2483 140.064 35.9509 135.767 32.2608 130.988Z"
      />
      <path
        d="M59.4733 23.3699C59.8547 23.3699 60.2419 23.2965 60.6144 23.1381C64.9352 21.3107 69.4555 19.9467 74.0696 19.0168C75.9381 24.9715 81.4411 29.3333 88.0029 29.3333C94.5648 29.3333 100.068 24.9715 101.936 19.0168C106.55 19.9437 111.071 21.3077 115.391 23.1381C115.764 23.2965 116.151 23.3699 116.533 23.3699C117.677 23.3699 118.762 22.6981 119.234 21.5805C119.865 20.0875 119.17 18.3656 117.677 17.7349C112.813 15.6787 107.718 14.1651 102.517 13.1589C101.749 5.78747 95.5739 0 88.0029 0C80.432 0 74.2544 5.78747 73.4888 13.1589C68.288 14.1651 63.1928 15.6787 58.3293 17.7349C56.8363 18.3656 56.1381 20.0875 56.7717 21.5805C57.2411 22.6981 58.3293 23.3699 59.4733 23.3699Z"
      />
      <path
        d="M29.92 46.1736C30.7971 46.1736 31.6653 45.7805 32.2432 45.0325C35.9363 40.2453 40.2336 35.9451 45.0149 32.2549C46.2968 31.2664 46.5344 29.4243 45.5429 28.1424C44.5515 26.8576 42.7093 26.6259 41.4275 27.6144C36.2501 31.6096 31.5979 36.2648 27.5997 41.448C26.6112 42.7328 26.8459 44.572 28.1307 45.5635C28.6646 45.9741 29.2952 46.1736 29.92 46.1736Z"
      />
      <path
        d="M81.444 61.5999L64.5363 53.146L47.6285 61.5999L64.5363 70.0537L81.444 61.5999Z"
      />
      <path
        d="M108.536 101.546L90.9363 110.346V127.254L108.536 118.454V101.546Z"
      />
      <path
        d="M94.5619 61.5999L111.47 70.0537L128.377 61.5999L111.47 53.146L94.5619 61.5999Z"
      />
      <path
        d="M114.403 92.0539L132.003 83.2539V66.3462L114.403 75.1462V92.0539Z"
      />
      <path
        d="M85.0696 66.3462L67.4696 75.1462V92.0539L85.0696 83.2539V66.3462Z"
      />
      <path
        d="M108.536 75.1462L90.9363 66.3462V83.2539L108.536 92.0539V75.1462Z"
      />
      <path
        d="M104.908 96.8001L88.003 88.3462L71.0952 96.8001L88.003 105.254L104.908 96.8001Z"
      />
    </SvgIcon>
  );
}
