import React from 'react';
import { useSnackbar } from 'notistack';

function InnerSnackbarUtilsConfigurator(props: any) {
  const { setUseSnackbarRef } = props; 
  setUseSnackbarRef(useSnackbar());
  return null;
}

let useSnackbarRef: any;

const setUseSnackbarRef = (useSnackbarRefProp: any) => {
  useSnackbarRef = useSnackbarRefProp;
};

export default function SnackbarUtilsConfigurator() {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
}

export const snackActions = {
  toast(msg: string, variant = 'success', autoHideDuration = 3000) {
    useSnackbarRef?.enqueueSnackbar(msg, { variant, autoHideDuration });
  },
};

export const showToast = (
  message: string,
  variant?: 'default' | 'error' | 'success' | 'warning' | 'info',
  autoHideDuration?: number
) => message && snackActions.toast(message, variant, autoHideDuration);
