import * as React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu, 
  Avatar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
  Brightness4, Brightness7, Notifications, LanguageOutlined, PersonOutline 
} from '@mui/icons-material';
import { styled, keyframes } from '@mui/material/styles';

import Routes from '@src/routes';
import { useColorMode } from '@providers/ColorModeContext';
import { dispatcher, getAppState, useAppSelector } from '@redux/store';
import { setAppFailureInternalAction, setAppLoggedUserAction } from '@redux/app-actions';
import LanguageArray from '@assets/constants/LanguageArray';
import { Logout } from '@modules/auth/api';
import LoggedUserControl from '@modules/auth/helpers/LoggedUserControl';
import PermissionControl from '@modules/auth/helpers/PermissionControl';
import { useSidebar } from '../core/providers/SidebarContext';

const ring = keyframes`
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
`;

const StyledRingBell = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'failure' })<{ failure?: boolean }>(({ failure }) => ({
  ...(failure && { 
    animation: `${ring} 4s .7s ease-in-out infinite;`
  })
}));

export default function HeaderAppBar() {
  const router = useRouter();
  const sidebar = useSidebar();
  const colorMode = useColorMode();
  const { t } = useTranslation();

  const { user, failure } = useAppSelector((state) => state.app);
  const [logoutControl, setLogoutControl] = React.useState(false);
  const [langAnchorEl, setLangAnchorEl] = React.useState<null | HTMLElement>(null);
  const [accountAnchorEl, setAccountAnchorEl] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    if (user === undefined && !logoutControl) { LoggedUserControl(); }
  }, [user, logoutControl]);

  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAccountAnchorEl(null);
  };

  const handleLangMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLangAnchorEl(event.currentTarget);
  };

  const handleLangMenuClose = () => {
    setLangAnchorEl(null);
  };

  const switchLanguage = (locale: string) => () => {
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale });
  };

  const routeTechnic = () => {
    router.push(Routes.technicErrors.path);
  };

  const logout = async () => {
    setLogoutControl(true);
    const { failureInterval } = getAppState().app;
    if (failureInterval !== undefined) {
      clearInterval(failureInterval);
      dispatcher(setAppFailureInternalAction()); 
    }
    await Logout();
    router.push(Routes.login.path).then(() => {
      dispatcher(setAppLoggedUserAction(undefined));
    });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {PermissionControl.Control(user?.roles, Routes.index.permission) ? (
            <Link href={Routes.index.path} passHref>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', sm: 'block', cursor: 'pointer' } }}
              >
                OPS!YON
              </Typography>
            </Link>
          ) : (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block', cursor: 'pointer' } }}
            >
              OPS!YON
            </Typography>
          )}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ ml: { xs: 0, sm: 5 } }}
            onClick={sidebar.toggleSidebar}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex' }}>
            <IconButton 
              size="large"
              edge="end"
              onClick={handleLangMenuOpen}
              color="inherit"
            >
              <LanguageOutlined />
            </IconButton>
            <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
              {colorMode.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
            <StyledRingBell
              failure={failure}
              size="large"
              color="inherit"
              aria-label={t('notifications', { ns: 'common' })}
              onClick={routeTechnic}
            >
              <Notifications />
            </StyledRingBell>

            <IconButton
              size="large"
              edge="end"
              onClick={handleAccountMenuOpen}
              color="inherit"
              aria-label={t('account', { ns: 'common' })}
            >
              <Avatar sx={{ textTransform: 'uppercase' }}>{user?.userName && user?.nameSurname[0]}</Avatar>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={accountAnchorEl}
        id="account-menu"
        open={Boolean(accountAnchorEl)}
        onClose={handleAccountMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {PermissionControl.Control(user?.roles, 'Admin|Manager') ? (
          <MenuItem onClick={() => { router.push(Routes.accountUsers.path); }}>
            <ListItemIcon>
              <PersonOutline fontSize="small" />
            </ListItemIcon>
            {user?.nameSurname}
          </MenuItem>
        ) : (
          <MenuItem>
            <ListItemIcon>
              <PersonOutline fontSize="small" />
            </ListItemIcon>
            {user?.nameSurname}
          </MenuItem>
        )}
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {t('logout')}
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={langAnchorEl}
        id="account-menu"
        open={Boolean(langAnchorEl)}
        onClose={handleLangMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {LanguageArray.map((lang) => (<MenuItem onClick={switchLanguage(lang.route)} key={lang.route}>{lang.name}</MenuItem>))}
      </Menu>
    </Box>
  );
}
