import { showToast } from '@components/utils/SnackbarUtils';
import RoutingService from '@services/RoutingService';
import { i18n } from 'next-i18next';

type DataType = {
  statusCode?: number;
  message: string;
};

export default function ErrorMiddleware(data: DataType) {
  const statusCode = data?.statusCode || 400;

  const message = data?.message || i18n?.t('generalErrorText') || '';
  if ([400, 401, 403, 500].includes(statusCode)) {
    showToast(message, 'error');
    if (statusCode === 401) RoutingService((routes, router) => router.push(routes.index.path));
  } else if ([600].indexOf(statusCode) >= 0 || !statusCode) {
    return () => {
      const messages = message.split('~');
      messages.forEach((m) => { showToast(m, 'warning'); });
    };
  }
}
