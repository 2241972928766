/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function FeedbackIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 161 161">
      <path
        d="M146.85 25.1562H132.702V9.43485C132.702 4.22405 128.478 0 123.267 0C118.056 0 113.832 4.22405 113.832 9.43485V18.2606C113.832 23.1224 110.71 27.3499 106.063 28.7797L98.1094 31.2271V80.1855C98.1094 80.3104 98.1031 80.4337 98.0999 80.5575C100.882 83.3395 104.655 84.9023 108.589 84.9023H146.85C154.665 84.9023 161 78.5671 161 70.752V39.3066C161 31.4915 154.665 25.1562 146.85 25.1562Z"
      />
      <path
        d="M83.959 84.9023H74.5254C71.9205 84.9023 69.8086 82.7905 69.8086 80.1855V29.873C69.8086 27.2681 71.9205 25.1562 74.5254 25.1562H83.959C86.5639 25.1562 88.6758 27.2681 88.6758 29.873V80.1855C88.6758 82.7905 86.5639 84.9023 83.959 84.9023Z"
      />
      <path
        d="M108.589 94.3359C103.415 94.3359 98.4914 92.7234 94.3828 89.7433C91.7942 92.564 88.0802 94.3359 83.959 94.3359H74.5254C66.7103 94.3359 60.375 88.0007 60.375 80.1855V55.6582H4.7168C2.11187 55.6582 0 57.7701 0 60.375V156.136C0 157.796 0.797453 159.39 2.2018 160.274C2.97378 160.76 3.84639 161 4.71648 161C5.63154 161 6.54408 160.734 7.33305 160.208L25.0122 148.422H124.209C126.814 148.422 128.926 146.31 128.926 143.705V94.3359H108.589ZM23.584 79.2422H50.3125C52.9174 79.2422 55.0293 81.3541 55.0293 83.959C55.0293 86.5639 52.9174 88.6758 50.3125 88.6758H23.584C20.9791 88.6758 18.8672 86.5639 18.8672 83.959C18.8672 81.3541 20.9791 79.2422 23.584 79.2422ZM105.342 131.127H23.584C20.9791 131.127 18.8672 129.015 18.8672 126.41C18.8672 123.805 20.9791 121.693 23.584 121.693H105.342C107.947 121.693 110.059 123.805 110.059 126.41C110.059 129.015 107.947 131.127 105.342 131.127ZM105.342 109.901H23.584C20.9791 109.901 18.8672 107.79 18.8672 105.185C18.8672 102.58 20.9791 100.468 23.584 100.468H105.342C107.947 100.468 110.059 102.58 110.059 105.185C110.059 107.79 107.947 109.901 105.342 109.901Z"
      />
    </SvgIcon>
  );
}
