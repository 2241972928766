import { createStore } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import RootReducer from './root-reducer';

const store = createStore(RootReducer);

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const dispatcher = store.dispatch;
export const getAppState = store.getState;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
