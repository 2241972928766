import {
  AccountAction,
  AccountActions,
  AccountStateType,
} from './account-actions';

const initialState: AccountStateType = {
  roles: [],
};

// eslint-disable-next-line default-param-last
export default function accountReducer(state = initialState, { type, payload }: AccountAction): AccountStateType {
  switch (type) {
    case AccountActions.SET_USER_ROLES:
      return { ...state, ...payload };
    case AccountActions.ADD_USER_ROLE: {
      if (payload.roles === undefined) return state;
      const added = payload.roles[0];
      const temp = { ...state };
      temp.roles?.push(added);

      return temp;
    }
    case AccountActions.REMOVE_USER_ROLE: {
      if (payload.roles === undefined) return state;
      const deleted = payload.roles[0];
      const temp = state.roles?.filter(
        (role) => role.roleId !== deleted.roleId
      );
      if (temp === undefined) return state;
      return { roles: temp };
    }
    default:
      return state;
  }
}
