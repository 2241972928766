import {
  AccountingAction,
  AccountingActions,
  AccountingStateType,
} from './accounting-actions';

const initialState: AccountingStateType = {
  businesses: [],
};

// eslint-disable-next-line default-param-last
export default function accountingReducer(state = initialState, { type, payload }: AccountingAction): AccountingStateType {
  switch (type) {
    case AccountingActions.SET_ACCOUNTING_BUSSINESSES:
      return { ...state, ...payload };
    default:
      return state;
  }
}
