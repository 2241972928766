/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function GenelIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 161 161">
      <path
        d="M143.653 65.679H83.0267C73.4616 65.679 65.6801 73.4604 65.6801 83.0255V115.964L49.9722 127.184C48.7328 128.068 47.9971 129.498 47.9971 131.021C47.9971 132.546 48.7328 133.975 49.9722 134.86L65.8693 146.215C67.1112 154.57 74.3325 161 83.0267 161H143.653C153.218 161 161 153.219 161 143.652V83.0255C161 73.4604 153.218 65.679 143.653 65.679ZM123.268 131.957C122.156 131.957 121.298 131.605 121.046 130.644L119.125 123.924H107.554L105.635 130.644C105.382 131.605 104.524 131.957 103.412 131.957C101.643 131.957 99.2689 130.847 99.2689 129.23C99.2689 129.128 99.3193 128.927 99.3696 128.724L109.121 96.9449C109.576 95.4292 111.445 94.7204 113.315 94.7204C115.235 94.7204 117.104 95.4292 117.559 96.9449L127.311 128.724C127.361 128.927 127.411 129.078 127.411 129.23C127.411 130.795 125.037 131.957 123.268 131.957Z"
      />
      <path
        d="M56.2466 83.0255C56.2466 76.6922 58.4613 70.8699 62.1499 66.2796C56.7686 66.2796 51.7754 64.5956 47.6593 61.7348C43.5432 64.5968 38.55 66.2796 33.1674 66.2796C31.6996 66.2796 30.5081 65.0881 30.5081 63.619C30.5081 62.1499 31.6996 60.9597 33.1674 60.9597C36.9372 60.9597 40.465 59.9156 43.4854 58.1075C39.8422 54.1756 37.4199 49.0989 36.834 43.4805H33.1687C31.6996 43.4805 30.5081 42.2903 30.5081 40.8212C30.5081 39.3521 31.6996 38.1606 33.1687 38.1606H45.0012V31.702C45.0012 30.2317 46.1914 29.0415 47.6605 29.0415C49.1296 29.0415 50.3199 30.2317 50.3199 31.702V38.1606H62.1524C63.6215 38.1606 64.813 39.3521 64.813 40.8212C64.813 42.2903 63.6215 43.4805 62.1524 43.4805H58.4871C57.9011 49.0989 55.4789 54.1756 51.8356 58.1075C54.8549 59.9181 58.3839 60.9597 62.1524 60.9597C63.5564 60.9597 64.7036 62.048 64.8031 63.4274C69.5875 58.9759 75.9932 56.2453 83.0279 56.2453H95.3211V45.0356L111.029 33.816C112.267 32.9316 113.003 31.5018 113.003 29.9787C113.003 28.4543 112.267 27.0245 111.029 26.1401L95.1319 14.7854C93.8888 6.43032 86.6675 0 77.9733 0H17.3477C7.78149 0 0 7.78149 0 17.3465V77.9745C0 87.5396 7.78149 95.3211 17.3477 95.3211H56.2466V83.0255Z"
      />
    </SvgIcon>
  );
}
