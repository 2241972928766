import IRoleListDTO from '../types/IRoleListDTO';

export enum AccountActions {
  SET_USER_ROLES = 'SET_USER_ROLES',
  REMOVE_USER_ROLE = 'REMOVE_USER_ROLE',
  ADD_USER_ROLE = 'ADD_USER_ROLE',
}

export type AccountStateType = {
  roles?: Array<IRoleListDTO>;
};

export type AccountAction = {
  type: AccountActions;
  payload: AccountStateType;
};

export function setUserRoles(roles: Array<IRoleListDTO>): AccountAction {
  return {
    type: AccountActions.SET_USER_ROLES,
    payload: { roles },
  };
}

export function addUserRoles(role: IRoleListDTO): AccountAction {
  return {
    type: AccountActions.ADD_USER_ROLE,
    payload: { roles: [role] },
  };
}

export function removeUserRoles(role: IRoleListDTO): AccountAction {
  return {
    type: AccountActions.REMOVE_USER_ROLE,
    payload: { roles: [role] },
  };
}
