/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function TechnicIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 134 134">
      <path
        d="M128.856 102.85L128.849 102.843L95.3392 73.1942C93.7846 71.8173 91.4307 71.8913 89.9611 73.3569L84.437 78.8811L77.5873 72.0316L89.7926 57.1741C101.193 63.0808 115.564 61.2543 125.132 51.6887C132.644 44.1776 135.384 33.6986 133.35 24.0257C132.751 21.1704 129.22 20.1183 127.158 22.1832L114.302 35.0386C111.968 37.3732 108.183 37.3732 105.849 35.0386C103.356 32.546 101.453 30.643 98.9603 28.1504C96.6258 25.8159 96.6258 22.0317 98.9603 19.6998L111.816 6.84175C113.881 4.7794 112.829 1.24907 109.974 0.64973C100.3 -1.38383 89.8211 1.35637 82.3098 8.86746C72.7439 18.4357 70.9171 32.8064 76.8241 44.2064L61.9663 56.4114L31.6684 26.1135L35.0236 22.7585C36.4029 21.3716 36.261 19.1091 34.7422 17.9062L17.3795 3.61213C16.084 2.58698 14.2297 2.69428 13.0601 3.86391L3.86459 13.0594C2.69496 14.229 2.58766 16.0833 3.61281 17.3786L17.909 34.7389C19.1362 36.2886 21.4069 36.3747 22.7594 35.0221L26.1165 31.665L55.8709 61.4186L3.98053 104.044C-0.929327 107.899 -1.36378 115.175 3.04881 119.59C4.25272 120.794 13.2035 129.744 14.4074 130.948C18.8226 135.361 26.0984 134.926 29.9536 130.016L72.5798 78.1273L78.8854 84.4327L73.1467 90.1714C71.6735 91.6483 71.6088 94.015 73.0004 95.5696L102.918 128.925C108.832 135.485 118.985 135.695 125.171 129.5L129.504 125.168C135.741 118.931 135.441 108.706 128.856 102.85ZM22.5626 115.361C20.3924 115.361 18.6368 113.602 18.6368 111.435C18.6368 109.265 20.3956 107.509 22.5626 107.509C24.7255 107.509 26.4884 109.259 26.4884 111.435C26.4884 113.602 24.7323 115.361 22.5626 115.361Z"
      />
    </SvgIcon>
  );
}
